import React from "react";
import { Link } from "gatsby";
import * as styles from "./BiographyList.module.scss";

const BiographyList = ({
  list,
  setPlaces,
  setAll,
  startDate = true,
  endDate = false,
}) =>
  list.map(({ node }, index) => {
    const startDateYear =
      node.startDate && startDate
        ? new Date(node.startDate).getFullYear()
        : "hi";
    const endDateYear =
      node.endDate && endDate ? new Date(node.endDate).getFullYear() : null;
    const separator = startDate && endDate ? " - " : "";
    let date = startDateYear + separator + endDateYear;
    if (startDate && !endDate) {
      date = startDateYear;
    } else if (!startDate && endDate) {
      date = endDateYear;
    }

    return (
      <Link
        key={index}
        to={`/biography/${node.slug.current}/`}
        className={styles.bioItem}
        onMouseEnter={() => {
          if (node.location) {
            setPlaces([
              {
                name: node.title,
                slug: node.slug.current,
                longitude: node.location?.lng,
                latitude: node.location?.lat,
              },
            ]);
            setAll(false);
          }
        }}
      >
        <article>
          <div className={styles.date}>{date}</div>
          <h3 className={styles.title}>{node.title}</h3>
          {/* <button
            className={styles.map}
            onClick={(e) => {
              e.preventDefault();
              if (node.location) {
                setPlaces([
                  {
                    name: node.title,
                    longitude: node.location?.lng,
                    latitude: node.location?.lat,
                  },
                ]);
              }
            }}
          >
            {node.location?.lng && node.location?.lat && <>✈️</>}
          </button> */}
        </article>
      </Link>
    );
  });

export { BiographyList };
